// @ts-ignore
import React, { useEffect, useState } from 'react';
import { graphql } from 'gatsby';
import Layout from '../layout';
import Navbar from '../components/Navbar/Navbar';
import { Button, Container, Grid, useMediaQuery, useTheme, makeStyles } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { getImage } from 'gatsby-plugin-image';
import { ThemeProvider } from '@material-ui/core/styles';
import { darkTheme } from '../theme';
import { ContentCard } from '../components/ContentCard/ContentCard';
import JsxSeo from '../components/SEO/JsxSEO';

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(20),
  },
  title: {
    textTransform: 'uppercase',
    marginTop: theme.spacing(20),
    marginBottom: theme.spacing(20),
  },
  thatsAll: {
    margin: theme.spacing(20),
  },
}));

function Blog({ data, location }) {
  const classes = useStyles();
  const allPostsUnsorted = [...data.allMarkdownRemark.edges]; //...data.allFirestorePosts.edges,

  function compare(a, b) {
    let dateA = new Date(a.node.frontmatter.date);
    let dateB = new Date(b.node.frontmatter.date);

    let comparison = 0;
    if (dateA > dateB) {
      comparison = -1;
    } else if (dateA < dateB) {
      comparison = 1;
    }
    return comparison;
  }

  const allPosts = allPostsUnsorted.sort(compare);

  const [postList, setPostList] = useState([...allPosts.slice(0, 6)]);
  const theme = useTheme();
  const smDown = useMediaQuery(theme.breakpoints.down('sm'));

  // State to trigger oad more
  const [loadMore, setLoadMore] = useState(false);

  // State of whether there is more to load
  const [hasMore, setHasMore] = useState(allPosts.length > 5);

  // Load more button click
  const handleLoadMore = () => {
    setLoadMore(true);
  };

  useEffect(() => {
    if (loadMore && hasMore) {
      const currentLength = postList.length;
      const isMore = currentLength < allPosts.length;
      const nextResults = isMore ? allPosts.slice(currentLength, currentLength + 8) : [];
      setPostList([...postList, ...nextResults]);
      setLoadMore(false);
    }
  }, [loadMore, hasMore, allPosts, postList]);

  useEffect(() => {
    const isMore = postList.length < allPosts.length;
    setHasMore(isMore);
  }, [postList, allPosts]);

  return (
    <ThemeProvider theme={darkTheme}>
      <Layout>
        <JsxSeo
          title="Blog"
          location={location}
          description="We are a group seasoned professionals in product management, UX, design and scalable software architectures. We nourish software craftsmanship! "
        />
        <Navbar />
        <Container className={classes.root} maxWidth={false}>
          <Typography align="left" variant="h1" className={classes.title}>
            Hot & Spicy News
          </Typography>
          <Grid container spacing={smDown ? 4 : 10} justifyContent={'space-around'}>
            {postList.map((post, index) => (
              <Grid item md={6} key={index} style={{ paddingTop: smDown ? 0 : index % 2 > 0 ? 80 : 0 }}>
                <ContentCard
                  to={`/blog${post.node.fields.slug}`}
                  cover={getImage(post.node.frontmatter.featuredImage)}
                  title={post.node.frontmatter.title}
                  subtitle={`${post.node.frontmatter.author?.name || post.node.frontmatter.author} • ${new Date(
                    post.node.frontmatter.date
                  ).toDateString()} • ${post.node.timeToRead} minute read`}
                />
              </Grid>
            ))}
          </Grid>
          {hasMore ? (
            <Box marginY="12" textAlign="center">
              <Button color="primary" size="large" onClick={handleLoadMore}>
                Load More Posts
              </Button>
            </Box>
          ) : (
            <Typography variant="h2" align="center" className={classes.thatsAll}>
              That's all folks!
            </Typography>
          )}
        </Container>
      </Layout>
    </ThemeProvider>
  );
}

export default Blog;

/* eslint no-undef: "off" */
export const query = graphql`
  query BlogQuery {
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { fileAbsolutePath: { regex: "/(posts)/" } }
    ) {
      edges {
        node {
          timeToRead
          id
          frontmatter {
            title
            date
            author {
              id
              name
            }
            featuredImage {
              childImageSharp {
                gatsbyImageData(quality: 100, width: 700, height: 315, placeholder: BLURRED)
              }
            }
          }
          fields {
            slug
          }
        }
      }
    }
  }
`;

/*

 allFirestorePosts(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          id
          frontmatter {
            title
            date
            author {
              name
            }
            featuredImage {
              childImageSharp {
                gatsbyImageData(width: 700, height: 500, placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
              }
            }
          }
          fields {
            slug
          }
        }
      }
    }
 */
